export default [
  "Foundation or Endowment",
  "Family Office",
  "Faith-Based",
  "Insurance Provider",
  "Investment Manager | Mainstream",
  "Investment Manager | Impact",
  "Investment Manager | Private Equity",
  "Investment Manager | Venture Capital",
  "Private Pension Fund",
  "Public Pension Fund",
  "Sovereign Wealth Fund",
];
